export const RemoveCookieStyles = () => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const styles = document.getElementById('onetrust-style');
  if (styles && head.contains(styles)) {
    head.removeChild(styles);
    SetupCookieBanner();
  }
};

export const SetupCookieBanner = () => {
  const outlineButton = document.getElementById('onetrust-pc-btn-handler');
  const link = document.getElementById('onetrust-accept-btn-handler');
  const allowAllButton = document.getElementById('accept-recommended-btn-handler');
  const closeModalButton = document.getElementById('close-pc-btn-handler');
  const confirmButton = document.querySelector<HTMLElement>('.save-preference-btn-handler');
  const privacyLink = document.querySelector<HTMLElement>('.privacy-notice-link');
  const banner = document.getElementById('onetrust-banner-sdk');
  const persistentButton = document.querySelector<HTMLElement>('.ot-floating-button');

  if (outlineButton) {
    outlineButton.innerHTML = `<span>${outlineButton.innerText}</span>`;
  }

  if (allowAllButton) {
    allowAllButton.innerHTML = `<span>${allowAllButton.innerText}</span>`;
  }

  if (confirmButton) {
    confirmButton.innerHTML = `<span>${confirmButton.innerText}</span>`;
  }

  if (privacyLink) {
    privacyLink.classList.add('link');
    privacyLink.classList.add('link--external');

    const svg = `
    <span class='link__icon'>
        <svg class='external' role='presentation'>
          <use xlink:href='#external-link'></use>
        </svg>
      </span>`;

    privacyLink.innerHTML = `<span class="link__text">${privacyLink.innerText}</span>${svg}`;
  }

  if (closeModalButton) {
    const svg = `
      <svg role="presentation">
        <use xlink:href="#close"></use>
      </svg>`;
    closeModalButton.innerHTML = `${svg}`;
  }

  if (link) {
    link.classList.add('link');
    link.classList.add('link__text--light-mode');
    link.innerHTML = `<span>${link.innerText}</span>`;
  }

  if (banner) {
    banner.classList.add('cookie-banner--show');
  }

  if (persistentButton && !persistentButton.classList.contains('ot-hide')) {
    persistentButton.classList.add('cookie-banner--show');
  }
};
