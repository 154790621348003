import 'focus-visible';
import create from '@verndale/core';
import modules from './modules';
import { RemoveCookieStyles } from './helpers/removeCookieStyles';
import { TrackLinks } from './helpers/dataLayer';

const isStorybook = !!document.getElementById('storybook-root');
const body = document.querySelector('body') as HTMLBodyElement;

document.addEventListener(
  'DOMContentLoaded',
  async () => {
    if (!body.dataset.modulesLoaded || isStorybook) {
      create(modules).then(() => {
        body.dataset.modulesLoaded = 'true';
      });
      setTimeout(() => {
        RemoveCookieStyles();
      }, 1000);

      TrackLinks();
    }
  },
  false
);
