import React from 'react';
import type { Organism } from '@verndale/core';
import globModules from './glob-modules';
import { renderElement } from './helpers/react-18-shim';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reactModule = (Component: any, nodeList: NodeListOf<HTMLElement>) => {
  return nodeList.forEach(node => {
    renderElement(<Component {...node.dataset} />, node);
  });
};

const modules: Organism[] = [
  {
    name: 'accordion',
    loader: () => import('./modules/accordion')
  },
  {
    name: 'mockApi',
    loader: () => import('./modules/mockApi'),
    render: reactModule
  },
  {
    name: 'bubble-images-and-text-row',
    loader: () => import('./modules/bubble-images-and-text-row')
  },
  {
    name: 'media-content-row',
    loader: () => import('./modules/media-content-row')
  },
  {
    name: 'alert-banner',
    loader: () => import('./modules/alert-banner')
  },
  {
    name: 'simple-masthead',
    loader: () => import('./modules/simple-masthead')
  },
  {
    name: 'homepage-masthead',
    loader: () => import('./modules/homepage-masthead')
  },
  {
    name: 'media-masthead',
    loader: () => import('./modules/media-masthead')
  },
  {
    name: 'video',
    loader: () => import('./modules/video')
  },
  {
    name: 'tabbed-cards-container',
    loader: () => import('./modules/tabbed-cards-container')
  },
  {
    name: 'image-card',
    loader: () => import('./modules/image-card')
  },
  {
    name: 'case-study',
    loader: () => import('./modules/case-study')
  },
  {
    name: 'image-and-text-cards-carousel',
    loader: () => import('./modules/image-and-text-cards-carousel')
  },
  {
    name: 'product-images-carousel',
    loader: () => import('./modules/product-images-carousel')
  },
  {
    name: 'link-listing',
    loader: () => import('./modules/link-listing')
  },
  {
    name: 'informational-card-list',
    loader: () => import('./modules/informational-card-list')
  },
  {
    name: 'content-cards-container',
    loader: () => import('./modules/content-cards-container')
  },
  {
    name: 'jump-links',
    loader: () => import('./modules/jump-links')
  },
  {
    name: 'authentication-modal',
    loader: () => import('./modules/authentication-modal')
  },
  {
    name: 'ProductSearchListing',
    loader: () => import('./modules/react/ProductSearchListing'),
    render: reactModule
  },
  {
    name: 'country-menu',
    loader: () => import('./modules/country-menu')
  },
  {
    name: 'variants-table',
    loader: () => import('./modules/variants-table')
  },
  {
    name: 'resource-masthead',
    loader: () => import('./modules/resource-masthead')
  }
];

export default [...globModules, ...modules];
