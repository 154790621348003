import type { Organism } from '@verndale/core';

const globModules: Organism[] = [
  {
    name: 'form',
    loader: () => import('./modules/form'),
  },
  {
    name: 'header',
    loader: () => import('./modules/global/header')
  },
  {
    name: 'language-btn-desktop',
    loader: () => import('./modules/global/language-btn-desktop')
  },
  {
    name: 'search',
    loader: () => import('./modules/global/search')
  }
];

export default globModules;
